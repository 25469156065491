<template>
	<div>
		<div
			class="banner_wrapper"
			:style="{ 'background-image': 'url(' + mainBanner + ')' }"
		>
			<div class="banner">
				<div class="banner_content">
					<div class="banner_title">
						{{ mainTitle }}
					</div>
					<div class="banner_button">
						<Button
							color="surface"
							:link="{ name: 'org_slug-products', params: { org_slug: organizationProfile?.slug } }"
						>
							<span class="ml-[8px]">Discover our newest materials</span>
							<Icon name="md-chevron_right" />
						</Button>
					</div>
				</div>
			</div>
		</div>

		<!-- <div
			v-if="additionalData && additionalData.categories.length > 0"
			class="categories_wrapper"
		>
			<div class="categories">
				<div class="collection_title">Top categories</div>
				<div class="collection_contents">
					<Link
						v-for="(category, index) in additionalData.categories"
						:key="index"
						:href="`/${organizationProfile?.slug}/products?category=${category.id}`"
					>
						{{ category.text }}
					</Link>
				</div>
			</div>
		</div> -->

		<!-- <div
			v-if="additionalData && additionalData.collections.length > 0"
			class="collections_wrapper"
		>
			<div class="collections">
				<div class="collection_title">Collections</div>
				<div class="collection_contents">
					<Link
						v-for="collection in additionalData.collections"
						:key="collection.name"
						:href="`/${organizationProfile?.slug}/collections/${collection.id}`"
					>
						{{ collection.name }}
					</Link>
				</div>
			</div>
		</div> -->

		<div
			v-if="platformProducts.length > 0"
			class="new_product_wrapper"
		>
			<div class="new_product">
				<div class="products_header">
					<div class="products_title">Newest products</div>
					<NuxtLink :to="{ name: 'org_slug-products', params: { org_slug: organizationProfile?.slug } }">
						<div class="view_all">
							<span>View all</span>
							<Icon name="md-chevron_right" />
						</div>
					</NuxtLink>
				</div>
				<div class="product_cards">
					<ProductCardComponent
						v-for="product in platformProducts"
						:key="product.id"
						:product="product"
						:organization-slug="organizationProfile?.slug || ''"
					/>
				</div>
			</div>
		</div>
		<!-- <ViewedProducts /> -->
	</div>
</template>

<script lang="ts" setup>
// import Link from "@/components/Link.vue";
// import ViewedProducts from "@/components/ViewedProducts.vue";
import type { PlatformCatalogProduct } from "@/types/products";
import ProductCardComponent from "@/components/ProductCardComponent.vue";

const { imgixEcmOdcDomain } = useRuntimeConfig().public;
const {
	// listCategoriesForShowroom,
	// listCollections,
	listProductsForPlatform,
} = usePM();

definePageMeta({
	layout: "organization",
});
const orgStore = useOrgStore();
const { organizationProfile, platformId } = storeToRefs(orgStore);
useHead({
	title: organizationProfile.value?.public_name || "",
});

// const { data: additionalData } = await useAsyncData("collections-and-categories", async () => {
// 	if (!organizationProfile.value) {
// 		throw new Error("Organization is not defined");
// 	}
// 	const [categories, collections] = await Promise.all([
// 		listCategoriesForShowroom(organizationProfile.value.organization_id),
// 		listCollections(organizationProfile.value.organization_id),
// 	]);
// 	return { categories, collections };
// });

const mainTitle = ref("Custom title + bckg img defined within the merchant admin panel");
const mainBanner = ref("");
const { getPlatformSettingsPlatform } = useECMApi();

const { imgixEcmWpcDomain } = useRuntimeConfig().public;

watchEffect(async () => {
	if (organizationProfile.value?.organization_id) {
		const { response } = await getPlatformSettingsPlatform(organizationProfile.value.organization_id);
		mainTitle.value =
			(response?.data as any)?.platform_settings?.[0]?.platform_branding?.platform_colorset?.custom_titlebar_text || "";

		mainBanner.value = `https://${imgixEcmWpcDomain}/${platformId.value}/${
			(response?.data as any)?.platform_settings?.[0]?.platform_branding?.platform_colorset?.custom_titlebar_background
		}`;
	}
});

const platformProducts = ref<PlatformCatalogProduct[]>([]);

watchEffect(async () => {
	await nextTick();
	if (platformId.value) {
		const platformCatalogProductsResult = await listProductsForPlatform(platformId.value);
		platformCatalogProductsResult.platformCatalogProducts.forEach((product: PlatformCatalogProduct) => {
			product?.images_textiles_items?.forEach((image: any) => {
				image.imageUrl = `https://${imgixEcmOdcDomain}/${image.image_path}?w=310&h=194&auto=compress`;
			});
		});
		platformProducts.value = platformCatalogProductsResult.platformCatalogProducts.splice(0, 8);
	}
});
</script>
<style lang="scss" scoped>
.banner_wrapper {
	@apply h-[400px] bg-cover mt-[37px] mb-[48px];
	@apply mobile:h-[300px] mobile:mb-[25px];
	.banner {
		@apply h-[400px] mobile:h-[300px] flex items-center justify-center max-w-[1440px] mx-auto;

		.banner_content {
			@apply w-[56%] flex flex-col justify-center mobile:w-[92.5%];

			.banner_title {
				@apply text-[50px] leading-[58px] font-normal font-dm-serif text-center text-white mb-[20px];
				@apply mobile:text-[30px] mobile:leading-[36px];
			}

			.banner_button {
				@apply flex justify-center;
			}
		}
	}
}
.categories,
.collections {
	@apply tablet:px-[110px];
	@apply mb-[48px] mobile:mb-[25px] mobile:px-[15px] max-w-[1360px] mx-auto;
	.collection_title {
		@apply text-[40px] leading-[48px] font-dm-serif font-normal text-neutral-900;
		@apply mobile:text-[24px] mobile:leading-[40px] mb-[24px] mobile:mb-[10px];
	}
	.collection_contents {
		@apply flex gap-[20px] flex-wrap mobile:flex-nowrap mobile:gap-[5px] overflow-auto;
	}
}

.new_product {
	@apply mb-[48px] mobile:mb-[25px] mobile:px-[15px] max-w-[1360px] mx-auto;
	.products_header {
		@apply flex items-center justify-between mb-[24px] mobile:mb-[10px];
		.products_title {
			@apply text-[40px] leading-[48px] font-dm-serif font-normal text-neutral-900;
			@apply mobile:text-[24px] mobile:leading-[40px];
		}
	}

	.product_cards {
		@apply flex gap-[40px] flex-wrap mobile:gap-x-[4.4%];

		#new_product_card {
			@apply mobile:w-[47.7%];
		}
	}
}

.new_product {
	@apply tablet:px-[4%];
	.view_all {
		@apply text-[16px] leading-[24px] font-normal text-blue-200 flex items-center;
	}
}
</style>
